import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const YourPdfViewerComponent = () => {
  return (
    <Grid container justifyContent="center" width="100%">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100vh',
          width: '100%', // Set width to 100% to fill the screen horizontally
          margin: 0,
          padding: 0,
          overflow: 'auto', // Enable scrolling
        }}
      >
        <iframe
          title=""
          src="/2024.pdf"
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        />
      </Box>
    </Grid>
  );
};

export default YourPdfViewerComponent;
